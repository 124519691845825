
<script setup>
    import { computed } from "vue";

    import { utils } from "o365-utils";

    defineOptions({ inheritAttrs: false });

    const props = defineProps({
        modelValue: { type: null   , required: true                  },
        disabled  : { type: Boolean, required: false, default: false },
    });
    const emit = defineEmits(["update:modelValue"]);

    const value = computed(() => {
        const date = utils.getValidDate(props.modelValue);
        if (date) {
            const offset = date.getTimezoneOffset() * 1000 * 60;
            const newDate = new Date(date - offset);
            return newDate.toISOString().slice(0, -8);
        } else {
            return null;
        }
    });


    /* handlers */

    function onInput(e) {
        emit("update:modelValue", utils.getValidDate(e.target.value));
    }

    function onClick(e) {
        e.target.showPicker();
    }

    function onClear() {
        if (!props.disabled) {
            emit("update:modelValue", null);
        }
    }
</script>

<template>
    <div style="position: relative;">
        <input
            v-bind="$attrs"
            type="datetime-local"
            :value="value"
            :disabled="disabled"
            @input="onInput"
            @click="onClick"
        />
        <a
            class="d-flex justify-content-center align-items-center text-muted"
            style="position: absolute; top: 0; right: 0; bottom: 0; aspect-ratio: 1;"
            @click="onClear()"
        >
            <i class="bi bi-x" style="font-size: 1.25em;" />
        </a>
    </div>
</template>

<style scoped>
    input {
        text-align: left !important;
    }
    input::-webkit-date-and-time-value {
        text-align: left;
    }
    input::-webkit-calendar-picker-indicator {
        color: transparent;
        background: none;
        z-index: 1;
    }
</style>
